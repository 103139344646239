<template>
  <div>
    <v-row id="footer" class="ma-0 secondary black--text" style="padding-top: 3rem 0; min-height: 450px">
      <v-col md="4" sm="12" :class="$vuetify.breakpoint.mdAndUp ? 'pl-10 pt-10' : 'px-4'">
        <h2>Quick Links</h2>
        <v-list-item v-for="item in quickLinks" :key="item.label" :to="item.link"
          :target="item.link.startsWith('//')? '_blank' : '_self'" class="blue-grey--text text--darken-3">
          <v-list-item-content>
            <v-list-item-title>{{ item.label }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <div>
          <h3 class="my-3">Listen to GG on</h3>
          <img v-for="item in playlists" :key="item.brand" :src="require(`@/assets/img/logos/${item.logo}`)" height="40"
            class="mx-3 brand_playlist" :onclick="`window.open('${item.link}', '_blank');`" :alt="item.brand"
            :title="`Listen GG on ${item.brand}`" />
        </div>
      </v-col>
      <v-col md="4" cols="12" class="mt-auto text-center">
        <div class="mb-1 d-flex justify-center align-center">
          <div class="text-right">
            <span class="caption">Website designed and developed by</span><br />
            <a href="mailto:code.with.abhimukh+meghpakhi@gmail.com" class="
                primary--text
                text--darken-3
                px-2
                subtitle
                text-decoration-none
                font-weight-bold
              ">code-with-abhimukh</a><br />
          </div>
          <div>
            <img :src="require('@/assets/logo-avi.jpg')" :height="$vuetify.breakpoint.mdAndUp ? 64 : 46" class="mx-3 brand_playlist elevation-12" style="border-radius: 50%; border: 4px solid white;" />
          </div>
        </div>

        <div class="text-center">
          <v-dialog v-if="!isUserAuthenticated" v-model="loginDialog" width="420">
            <template v-slot:activator="{ on, attrs }">
              <v-btn x-small color="secondary darken-1" dark class="mt-6" v-bind="attrs" v-on="on">Admin
                Login</v-btn>
            </template>
            <v-card class="py-3">
              <login @loggedin="loginDialog = false"></login>
            </v-card>
          </v-dialog>
          <v-btn v-else x-small color="tincent darken-2" dark class="mt-6" @click="logout">Logout</v-btn>
        </div>
      </v-col>
    </v-row>
    <div style="background: #353535" class="text-center grey--text py-3"
      :style="{ height: $vuetify.breakpoint.mdAndUp ? '120px' : '200px' }">
      <span class="caption"><strong>Disclaimer</strong><br />
        This website has been created by an admirer of Sri Goutam Ghosal.
        <br />
        This is not the official website of the veteran singer.</span><br />
      <span class="subtitle-2"> &copy; 2025 meghpakhi </span>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'AppFooter',
  data: () => ({
    quickLinks: [
      {
        label: 'Songs by GG',
        link: 'discography?singer=GG'
      },
      {
        label: 'Songs by other artists created by GG',
        link: 'discography?singer=notGG'
      },
      {
        label: 'Music Academy',
        link: 'academy'
      },
      {
        label: 'Admission form',
        link: '//forms.gle/j5uYF9JbVUdbYA4d6'
      }
    ],
    playlists: [
      /* {
        brand: 'Wynk',
        link: '//wynk.in/music/artist/goutam-ghosal/wa_e4050333',
        logo: 'wynk.png'
      }, */
      {
        brand: 'YoutubeMusic',
        link: '//music.youtube.com/playlist?list=OLAK5uy_nbNGVlylyU06NGKmg-kSJYX_JKveuGjtA',
        logo: 'ytmusic.png'
      },
      {
        brand: 'Spotify',
        link: '//open.spotify.com/artist/5zhPWJJ1qOXbLSAnOvYVDY',
        logo: 'spotify.png'
      },
      {
        brand: 'Gaana',
        link: '//gaana.com/artist/goutam-ghosal',
        logo: 'gaana.png'
      },
      {
        brand: 'JioSaavn',
        link: '//www.jiosaavn.com/artist/goutam-ghoshal-songs/CkyMUBoq7TA_',
        logo: 'jiosaavn.png'
      },
      {
        brand: 'AppleMusic',
        link: '//music.apple.com/us/artist/goutam-ghosal/1467353614',
        logo: 'applemusic.png'
      }
    ],
    loginDialog: false
  }),
  computed: {
    ...mapGetters(['isDarkMode', 'isUserAuthenticated'])
  },
  methods: {
    ...mapActions(['signout']),
    updateScroll (e) {
      if (e.target.scrollTop === 0) this.appbarColor = 'transparent'
      else this.appbarColor = '#111'
    },
    logout () {
      this.signout()
    }
  },
  components: {
    Login: () => import('@/views/Registration/Login')
  }
}
</script>

<style lang="scss" scoped>
#footer {
  background: url("~@/assets/img/site/footer-banner.jpeg") no-repeat center center !important;

  @media only screen and (max-width: 600px) {
    background: url("~@/assets/img/site/footer-banner-sm.jpeg") no-repeat center;
  }

  background-size: cover;
}

.brand_playlist {
  cursor: pointer;
  border-radius: 20%;
}
</style>
